html  {
    position: relative;
    min-height: 100%;
}

body  {
  margin-bottom: 156px !important;
  font-family: 'Roboto', sans-serif;
}

.App-logo {
  pointer-events: none;
}

.App-header {
  background-color: #fff;
  justify-content: center;
  text-align: center;
  font-size: 26px;
  color: #333;
  min-height: 100%;
}

.App-link {
  color: #61dafb;
}

h1 {
  font-weight: 700 !important;
  font-size: 3rem !important;
}

.location {
  font-weight: 300;
}

.temp {
  font-size: 96px;
  font-weight: 700;
}

.temp span {
  font-size: 36px
}

.navbar-light .navbar-toggler {
  color: rgba(0,0,0,.5);
  border: none !important;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100px;
  line-height: 100px;
  background-color: #f5f5f5;
}

.description {
  font-size: 20px;
  font-weight: 300;
  padding-bottom: 30px
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
